<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label text-dark">Transferir Reais</span>
            <span class="text-muted mt-2 font-weight-bold font-size-sm">
              Transfira valores em reais para outros usuários.
            </span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-lg-6">
            <b-form class="form" @submit.stop.prevent="onSubmit">
              <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Dados para transferência:</h3>

              <b-form-group label="Email ou endereço da carteira:">
                <b-input-group class="mt-3">
                  <b-form-input
                    v-model="$v.form.to.$model"
                    :state="validateState('to')"
                    placeholder="Endereço da carteira"
                    class="form-control form-control-solid h-auto py-5 px-6"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="success" class="rounded-right" @click="showModal">Escanear</b-button>
                  </b-input-group-append>

                  <b-form-invalid-feedback>{{ validationMsg($v.form.to) }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>

              <b-form-group label="Valor:">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                  <money
                    v-model="$v.form.value.$model"
                    v-bind="maskMoneyReal()"
                    class="form-control form-control-solid h-auto py-5 px-6 rounded"
                    :class="$v.form.value.$invalid ? 'is-invalid' : 'is-valid'"
                    :state="validateState('value')"
                  />
                  <b-form-invalid-feedback>{{ validationMsg($v.form.value) }}</b-form-invalid-feedback>
                </b-input-group>
                <br />
                <span class="text-success font-size-lg">
                  Saldo disponivel:
                  <strong class="text-success font-size-lg font-weight-boldest">
                    {{ currentWalletReal.balance | currency() }}
                  </strong>
                </span>
              </b-form-group>
              <!--begin::Action-->
              <div class="form-group d-flex justify-content-center border-top pt-10">
                <button
                  ref="btn_submit"
                  type="submit"
                  class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
                  :disabled="isSubmitting"
                  @click="onSubmit"
                >
                  Realizar transferência
                </button>
              </div>
              <!--end::Action-->
            </b-form>
          </div>
          <div class="col col-lg-6">
            <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Dados usuário que irá receber:</h3>
            <div class="card card-custom wave wave-animate wave-info mb-8 mb-lg-0 shadow">
              <div class="card-body">
                <div v-if="toWallet" class="d-flex align-items-center p-5">
                  <div class="mr-6 text-center">
                    <span class="symbol symbol-80">
                      <div
                        v-if="toWallet"
                        class="symbol-label"
                        :style="'background-image:url(' + toWallet.customer.avatar + ')'"
                      ></div>
                    </span>
                    <span :class="'badge badge-' + toWallet.customer.status.class">
                      {{ toWallet.customer.status.name }}
                    </span>
                  </div>
                  <div class="d-flex flex-column">
                    <a href="#" class="text-dark text-hover-primary font-weight-bold font-size-h4">
                      {{ toWallet.customer.name }}
                    </a>
                    <div class="text-dark-75">
                      <span class="badge badge-info mt-2">{{ toWallet.customer.email }}</span>
                    </div>
                    <span class="mt-2 font-weight-bold" style="word-break: break-all">
                      {{ toWallet.wallet.address }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <OverlayLoader :is-submitting="true" message="Aguardando usuário para transferência." :timing="true">
                    <div class="pb-5">
                      <!--begin::Header-->
                      <div class="d-flex flex-column flex-center">
                        <!--begin::Symbol-->
                        <div class="symbol symbol-120 symbol-circle symbol-success overflow-hidden">
                          <span class="symbol-label">
                            <img src="/media/svg/avatars/007-boy-2.svg" class="h-75 align-self-end" alt="" />
                          </span>
                        </div>
                      </div>
                      <!--end::Header-->
                    </div>
                  </OverlayLoader>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal ref="scan-qr-code" title="Escanear carteira" body-class="p-0" hide-footer centered>
          <div class="d-block text-center">
            <qrcode-stream @decode="onDecode" @init="onInit" />
            <p class="error">{{ qr_scan.error }}</p>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Money } from 'v-money';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';
import debounceAsyncValidator from '@/core/utils/validationDeBouce';
import { QrcodeStream } from 'vue-qrcode-reader';
import { validationMessage } from 'vuelidate-messages';
import formMessages from '@/core/utils/validationMessages';

export default {
  components: {
    Money,
    OverlayLoader,
    QrcodeStream
  },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      toWallet: null,
      form: {
        to: '',
        value: ''
      },
      qr_scan: {
        error: ''
      }
    };
  },
  computed: {
    ...mapGetters(['currentWalletReal'])
  },
  validations: {
    form: {
      to: {
        required,
        isValidWallet: debounceAsyncValidator(function (value, debounce) {
          // synchronous validations
          if (!value) return true;
          // capture all component state synchronously
          return debounce()
            .then(() => this.$store.dispatch('CHECK_WALLET_TO_TRANSFER_REAL', { to: value }))
            .then((response) => {
              this.toWallet = response;
              return true;
            })
            .catch(() => {
              // could be caused by either rest api failure or by debounce
              this.toWallet = null;
              return false;
            });
        }, 1000)
      },
      value: {
        required,
        minValueReal: minValue(1),
        balanceSufficient(value) {
          return value <= this.currentWalletReal.balance;
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [
      { title: 'Carteiras' },
      { title: 'Carteira Reais' },
      { title: 'Transferência' }
    ]);
  },

  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      const form = {
        to: this.$v.form.to.$model,
        value: this.$v.form.value.$model
      };

      const submitButton = this.$refs['btn_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('STORE_TRANSFER_REAL', form)
        .then((response) => {
          this.$swal({
            title: response.success,
            icon: 'success'
          });
          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');

          this.resetForm();

          //Transfer Notification Socket
          this.$socket.emit('transferNotification', response.data);
        })
        .catch(() => {
          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    resetForm() {
      this.toWallet = null;
      this.form.to = '';
      this.form.value = '';

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validationMsg: validationMessage(formMessages),
    onDecode(result) {
      this.form.to = result;
      this.$refs['scan-qr-code'].hide();
    },
    showModal() {
      this.$refs['scan-qr-code'].show();
    },
    hideModal() {
      this.$refs['scan-qr-code'].hide();
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.qr_scan.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.qr_scan.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.qr_scan.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.qr_scan.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.qr_scan.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qr_scan.error = 'ERROR: Stream API is not supported in this browser';
        }
      }
    }
  }
};
</script>
